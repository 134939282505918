import {
  Box,
  chakra,
  Divider,
  Flex,
  Heading,
  OrderedList,
  Text,
  UnorderedList,
} from '@chakra-ui/react';
import styled from '@emotion/styled';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import { GatsbyImage as Img, getImage } from 'gatsby-plugin-image';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import React from 'react';
import SEO from '../components/seo';
import { capitalize } from '../utils/string-functions';
import LinkButton from '../components/buttons/link-button';

const options = {
  // renderText: (text) => {
  //   return text.split('\n').reduce((children, textSegment, index) => {
  //     return [...children, index > 0 && <br key={index} />, textSegment];
  //   }, []);
  // },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (_node, children: React.ReactNode[]) => (
      <Text my={4}>{children}</Text>
    ),
    [BLOCKS.UL_LIST]: (node, children) => (
      <UnorderedList>{children}</UnorderedList>
    ),
    [BLOCKS.DOCUMENT]: (node, children) => <Text>{children}</Text>,
    [BLOCKS.HEADING_1]: (node, children) => (
      <Heading as="h1" size="xl">
        {children}
      </Heading>
    ),
    [BLOCKS.HEADING_2]: (node, children) => (
      <Heading as="h2" size="lg">
        {children}
      </Heading>
    ),
    [BLOCKS.OL_LIST]: (node, children) => <OrderedList>{children}</OrderedList>,
    [BLOCKS.QUOTE]: (_node, children) => (
      <Text
        my={8}
        textDecoration="italic"
        py={6}
        px={10}
        // bgColor="gray.100"
        border="1px solid"
        borderColor="gray.200"
        borderRadius="xl"
        color="gray.700"
      >
        {children}
      </Text>
    ),
  },
};

function BlogTemplate({
  pageContext: {
    title,
    author,
    datePublished,
    image,
    tags,
    authorBio,
    authorImage,
    articleBody,
  },
}) {
  const richTextBody = articleBody ? renderRichText(articleBody, options) : '';

  console.log('AUTHOR', author);

  return (
    <>
      <SEO title={`${title}`} description={JSON.stringify(tags)} />

      <Flex
        flexDir="column"
        w="100%"
        mt={{ base: 8, lg: 10 }}
        maxW="900px"
        mx="auto"
      >
        {image && (
          <Box
            w="100%"
            shadow="md"
            borderRadius="xl"
            overflow="hidden"
            mb={12}
            maxH="40vh"
          >
            <Img
              image={getImage(image)}
              imgStyle={{ objectFit: 'cover', objectPosition: 'center' }}
              alt="image"
            />
          </Box>
        )}
        <Flex
          w="100%"
          justifyContent="space-between"
          flexDir="column"
          mr={{ base: 0, lg: 16 }}
        >
          <Flex flexDir="column" w="100%">
            <Heading as="h1" mb={2}>
              {title}
            </Heading>
            {/* TODO: make tags clickable links */}
            {tags && (
              <Flex mt={2} mb={6}>
                {tags.map((tag) => (
                  <Text
                    fontWeight="semibold"
                    bgColor="gray.100"
                    color="gray.700"
                    py={1}
                    px={3}
                    borderRadius="2xl"
                    mr={4}
                    fontSize={14}
                  >
                    {capitalize(tag)}
                  </Text>
                ))}
              </Flex>
            )}
            <Flex justifyContent="flex-start" align="center" mb={6}>
              <Text color="gray.600" mr={3}>
                By{' '}
                <chakra.span fontWeight="semibold">
                  {author || 'Parentshop Staff'}
                </chakra.span>
              </Text>
              <Text fontSize={14} color="gray.500">
                {datePublished}
              </Text>
            </Flex>
            <chakra.article color="gray.700" whiteSpace="pre-wrap">
              {richTextBody}
            </chakra.article>
            {/* TODO: make parametric */}
            {/* <Flex
              flexDir="column"
              mt={6}
              mb={16}
              py={10}
              px={8}
              borderRadius="2xl"
              bgColor="white"
              boxShadow="md"
            >
              <Heading
                as="h3"
                fontWeight="semibold"
                color="gray.800"
                fontSize={26}
              >
                Upcoming courses
              </Heading>
              <Text my={6} lineHeight={1.6} color="gray.600">
                Parentshop is hosting a parent-led child development interactive
                webinar for Parents to learn the skills to identify and reduce
                anxiety and build resilience in their children. This webinar
                hosted by Parentshop Founder and No Scaredy Cats course creator
                Michael Hawton. It will be held over three, two hour long
                sessions over three consecutive Mondays, 16th, 23rd & 30th
                November from 7.00pm- 9.00pm AEDT for only $99.
              </Text>
              <Flex justifyContent="start" alignItems="center">
                <Button colorScheme="primary" mr={4}>
                  Register now
                </Button>
                <Button colorScheme="primary" variant="outline">
                  Learn more
                </Button>
              </Flex>
            </Flex> */}
          </Flex>
          {author && (
            <Flex
              flexDir="row"
              mt={6}
              mb={6}
              bgColor="gray.100"
              borderRadius="2xl"
              p={8}
              w="100%"
              h="100%"
              boxShadow="md"
            >
              <>
                <Box w="30%" borderRadius="lg" overflow="hidden">
                  <Img
                    alt="image"
                    image={getImage(authorImage)}
                    imgStyle={{ objectFit: 'cover', objectPosition: 'center' }}
                  />
                </Box>
                <Flex flexDir="column" ml={6}>
                  <Heading as="h3" size="md">
                    About the author
                  </Heading>
                  <Text color="gray.700" mt={1}>
                    {authorBio}
                  </Text>
                </Flex>
              </>
            </Flex>
          )}
        </Flex>
        <Flex my={8} w="100%" flexDir="column">
          <Divider mb={4} w="100%" />
          <LinkButton
            text="Back to blog"
            link="about/blog"
            iconPosition="left"
          />
        </Flex>
      </Flex>
    </>
  );
}

export default BlogTemplate;
